import React from "react";
import _ from 'lodash';

const Modal = (props) => {
  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
          
            {/* Background overlay, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100"
              To: "opacity-0" */}
          
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-50"></div>
          </div>

           {/* This element is to trick the browser into centering the modal contents.  */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
          
            {/* Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" */}
          
          {props.modal}
        </div>
      </div>
    </>
  );
}

export default Modal;