import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const Footer = (props) => {
  const [ menuOpen, setMenuOpen ] = useState(false);
  
  return (
    <>
      <footer className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
            <div className="px-5 py-2">
              <Link to="/" className="text-base text-gray-500 hover:text-gray-900">
                Home
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link to="/#map" className="text-base text-gray-500 hover:text-gray-900">
                Map
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link to="/#contact" className="text-base text-gray-500 hover:text-gray-900">
                Contact Us
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link to="/privacy-policy" className="text-base text-gray-500 hover:text-gray-900">
                Privacy Policy
              </Link>
            </div>

            <div className="px-5 py-2">
              <Link to="/terms-of-use" className="text-base text-gray-500 hover:text-gray-900">
                Terms of Use
              </Link>
            </div>
          </nav>
          <p className="mt-8 text-center text-base text-gray-400">
            &copy; 2021 That's So Tech LLC. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}


export default Footer;
