import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import logo from "../images/brand/logo-horizontal-on-white.png";

const Header = (props) => {
  const [ menuOpen, setMenuOpen ] = useState(false);
  
  return (
    <>
      <section id="home">
        <div className="bg-gray-50">
          <div className="relative">
            <div className="relative py-6">
              <div className="max-w-screen-2xl mx-auto px-4 sm:px-6">
                <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
                  <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <Link onClick={e => setMenuOpen(false)} to="/" aria-label="Home">
                        <img className="h-6 w-auto sm:h-8" src={logo} alt="CovidVaxMap" />
                      </Link>
                      <div className="-mr-2 flex items-center md:hidden">
                        <button onClick={e => setMenuOpen(true)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
                          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:flex md:space-x-10">
                    <Link onClick={e => setMenuOpen(false)} to="/" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Home</Link>
                    <Link onClick={e => setMenuOpen(false)} to="/#map" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Map</Link>
                    <Link onClick={e => setMenuOpen(false)} to="/#contact" className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out">Contact Us</Link>
                  </div>
                </nav>
              </div>
              
              {menuOpen && (
                <div className="absolute top-0 inset-x-0 p-2 transition transform z-10 origin-top-right md:hidden">
                  <div className="rounded-lg shadow-md">
                    <div className="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                      <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                          <img className="h-6 w-auto" src={logo} alt="" />
                        </div>
                        <div className="-mr-2">
                          <button onClick={e => setMenuOpen(false)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
                            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="px-2 pt-2 pb-3">
                        <Link onClick={e => setMenuOpen(false)} to="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Home</Link>
                        <Link onClick={e => setMenuOpen(false)} to="/#map" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Map</Link>
                        <Link onClick={e => setMenuOpen(false)} to="/#contact" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Contact Us</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


export default Header;
