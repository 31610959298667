import React, { useEffect } from "react";

const Announcement = (props) => {
  //const [] = useState(false);
  useEffect(() => {
    if (props.visible && document.getElementById('announcement-container')) {
      let height = document.getElementById('announcement-container').clientHeight;

      if (document.getElementById('bmc-wbtn')) {
        document.getElementById('bmc-wbtn').style.bottom = `${height + 12}px`;
      }
    }
  }, [ props.visible ]);

  return props.visible ? (
    <>
      <div id="announcement-container" className="fixed bottom-0 inset-x-0 pb-2 sm:pb-4">
        <div className="mx-auto px-2 sm:px-4">
          <div className="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center sm:justify-center md:text-lg">
                <p className="ml-3 font-medium text-white">
                  <span dangerouslySetInnerHTML={{ __html: props.announcement.body }}></span>
                </p>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button onClick={e => { document.getElementById('bmc-wbtn').style.bottom = `18px`; props.setAnnouncementVisible(false); }} type="button" className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Dismiss</span>
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default Announcement;