import React, { useState } from "react";
import Loading from "./Utility/Loading.jsx";
import Toast from "./Utility/Toast.jsx";
import Modal from "./Utility/Modal.jsx";
import Announcement from "./Utility/Announcement.jsx";
import PubSub from "pubsub-js";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "maplibre-gl/dist/maplibre-gl.css";
import 'mapbox-gl/dist/mapbox-gl.css';

import "../assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/vendor/@fortawesome/fontawesome-free/css/fontawesome.min.css";
import '../tailwind.output.css';

import Header from "./Header.jsx";
import Footer from "./Footer.jsx";

export default function Layout({ children }) {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ alert, setAlert ] = useState(null);
  const [ alertVisible, setAlertVisible ] = useState(false);
  const [ announcement, setAnnouncement ] = useState(null);
  const [ announcementVisible, setAnnouncementVisible ] = useState(true);
  const [ modal, setModal ] = useState(null);

  const alertSubscriber = (msg, data) => {
    setAlert(data);
    setAlertVisible(true);

    setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
  };
  const alertToken = PubSub.subscribe('alert', alertSubscriber);

  const announcementSubscriber = (msg, data) => {
    setAnnouncement(data);
    setAnnouncementVisible(true);
  };
  const announcementToken = PubSub.subscribe('announcement', announcementSubscriber);

  const loadingSubscriber = (msg, data) => {
    setIsLoading(data);
  };
  const loadingToken = PubSub.subscribe('loading', loadingSubscriber);

  const modalSubscriber = (msg, data) => {
    setModal(data);
  };
  const modalToken = PubSub.subscribe('modal', modalSubscriber);

  return (
    <>
      <Header />
        {children}
      <Footer />
      {isLoading && (
        <Loading />
      )}
      {alert && (
        <Toast visible={alertVisible} alert={alert} setAlertVisible={setAlertVisible} />
      )}
      {/* {announcement && (
        <Announcement visible={announcementVisible} announcement={announcement} setAnnouncementVisible={setAnnouncementVisible} />
      )} */}
      <Announcement visible={announcementVisible} announcement={{body: '🎉&nbsp;&nbsp;CovidVaxMap has been used over a million times! However, due to decreasing need, we will be slowly phasing out the project. Thanks!'}} setAnnouncementVisible={setAnnouncementVisible} />
      {modal && (
        <Modal modal={modal} />
      )}
    </>
  )
}